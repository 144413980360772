import React from 'react';
import PropTypes from 'prop-types';

import {
    Layout,
    Observer,
    AnimateText,
    AnimateImage,
    AnimateTitle,
    PracticeAreaBadge,
    RichTextToHtml,
} from '../../components';

import styles from './TeamMember.module.css';

const TRANSITION_DELAY = 200;

export const TeamMember = ({ pageContext, location }) => {
    const {
        name,
        role,
        area,
        photo,
        email,
        description,
    } = pageContext;

    const seoData = {
        title: `${name} - PA Advogados`,
        description: `${area.name} - ${role}`,
        image: `https:${photo.url}`,
    };

    return (
        <Layout
            seoData={ seoData }
            location={ location }
            className={ styles.main }>
            <Observer threshold={ 0.3 }>
                { (isVisible) => (
                    <section className={ styles.memberDetailsSection }>
                        <div className={ styles.outsiderBadgeWrapper }>
                            <AnimateText startAnimation={ isVisible }>
                                <PracticeAreaBadge
                                    id={ area.id }
                                    name={ area.name }
                                    className={ styles.outsiderBadge } />
                            </AnimateText>
                        </div>
                        <div className={ styles.container }>
                            <div className={ styles.imageWrapper }>
                                <AnimateImage
                                    src={ photo.url }
                                    alt={ photo.alt }
                                    className={ styles.image }
                                    startAnimation={ isVisible } />
                            </div>
                        </div>
                        <div className={ styles.info }>
                            <AnimateTitle startAnimation={ isVisible }>
                                <h1 className={ styles.name }>{ name }</h1>
                            </AnimateTitle>
                            <div className={ styles.badgeWrapper }>
                                <AnimateText
                                    startAnimation={ isVisible }
                                    style={ { transitionDelay: `${TRANSITION_DELAY * 1.8}ms` } }>
                                    <PracticeAreaBadge
                                        id={ area.id }
                                        name={ area.name }
                                        className={ styles.badge } />
                                </AnimateText>
                            </div>
                            <AnimateText
                                startAnimation={ isVisible }
                                className={ styles.roleContainer }
                                style={ { transitionDelay: `${TRANSITION_DELAY}ms` } }>
                                <p className={ styles.role }>{ role }</p>
                            </AnimateText>
                            { email && (
                                <AnimateText
                                    startAnimation={ isVisible }
                                    className={ styles.emailWrapper }
                                    style={ { transitionDelay: `${TRANSITION_DELAY * 2.2}ms` } }>
                                    <a
                                        className={ styles.email }
                                        href={ `mailto:${email}` }>
                                        { email }
                                    </a>
                                </AnimateText>
                            ) }
                            <AnimateText
                                startAnimation={ isVisible }
                                style={ { transitionDelay: `${TRANSITION_DELAY * 2.6}ms` } }>
                                <div className={ styles.desktopDescriptionContainer }>
                                    <RichTextToHtml>{ description }</RichTextToHtml>
                                </div>
                            </AnimateText>
                        </div>
                    </section>
                ) }
            </Observer>
            <section className={ styles.descriptionSection }>
                <RichTextToHtml>{ description }</RichTextToHtml>
            </section>
        </Layout>
    );
};

TeamMember.propTypes = {
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired, // Injected on page creation (gatsby-node.js)
};

export default TeamMember;
